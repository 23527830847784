import { AuthField } from "./authField"

type ValidationFunction = (value: string, signal?: AbortSignal) => PromiseLike<string | undefined>

export function validateFieldWith(field: AuthField, validationFunction: ValidationFunction) {
    async function validate(value: string, abortSignal: AbortSignal) {
        const error = await validationFunction(value, abortSignal)
        field.setValidationResult(error)
    }

    field.registerInlineValidationListener((abortSignal: AbortSignal) => {
        validate(field.value, abortSignal).catch((e: unknown) => {
            if(!abortSignal.aborted) console.error(e)
        })
    })
}
